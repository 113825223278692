import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";  
import { auth, getUser, fetchVersionInfo, signInWithCustomToken } from "./firebaseAuth";
import { addHours } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";


// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { titleCase, setWonLoss, dateStringForTimeZone, timeStringForTimeZone, getTeam, getMatch, updateMatch, getTeamMembers, setMatchAvailability, deletePractice } from "./firebaseData";
//import { Timestamp } from "firebase/firestore";
import "./MatchDetail.css";

function MatchDetail(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersById, setTeamMembersById] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [unavailablePlayers, setUnavailablePlayers] = useState([]);
  const [match, setMatch] = useState('');
  const editLineupModalRef = useRef();
  const editAvailableModalRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  

  const navigate = useNavigate();
  const params = useParams();
  //console.log("Displaying Match ID: ", params.matchID);
  //console.log("Params: ", params)
  //console.log("Props: ", props)

  async function fetchData(){
    const userDoc = await getUser(user);
    console.log("fetchData - Props: ", props);
    setVersionID(await fetchVersionInfo());

    if(userDoc.displayTeam){
      console.log('fetchData - Getting match, team, and teamMembers from the database...');
      var team = await getTeam(userDoc.displayTeam);
      var match = await getMatch(params.matchID);

      //Check to see if team matches... 
      //IF NOT: Redirect to /matches
      if((match.home !== userDoc.displayTeam) && (match.visitor !== userDoc.displayTeam)){
        return navigate("/matches");
      }
      
      if(team.season.includes('SUMMER') && match.lines && match.lines.length > 1 && match.lines[0].type === 'singles'){
        delete match.lines;
      }
      if(!match.lines || match.lines.length === 0){
        console.log('Adding empty lines...');

        let lineTimesPattern = /(\d).@.(\d*:\d*.[^\s]*)/g;
        let allLineTimesPattern = /(\d).@.(\d*:\d*.[^\s]*)/;
        let lineCountAndTimes = match.times.match(lineTimesPattern);
        let allLines = []
        console.log("Line Times Matches: ", lineCountAndTimes);
        lineCountAndTimes.forEach((firstLevelMatch) =>{
          var indivMatches = firstLevelMatch.match(allLineTimesPattern);
          allLines.push(indivMatches[1]);
          allLines.push(indivMatches[2]);
        });
        console.log("All Line Times Matches: ", allLines);
        let lineTimes = [];
        for(let i=0; i < allLines.length; i++){
          if(!isNaN(allLines[i])){
            let timeCount = parseInt(allLines[i]);
            for(let j=0; j < timeCount; j++){
              lineTimes.push(allLines[i+1]);
            }
          }
        }
        console.log("Line Times: ", lineTimes);

        let lines = [];
        if(team.season.includes('SUMMER')){
          for(let doublesCount = 0; doublesCount < team.lineCountDoubles; doublesCount++){
            lines.push({ type: "doubles", timeOnly: lineTimes[lines.length], time: match.date, players: []});
          }
          for(let singlesCount = 0; singlesCount < team.lineCountSingles; singlesCount++){
            lines.push({ type: "singles", timeOnly: lineTimes[lines.length], time: match.date, players: []});
          }
  
        } else {
          for(let singlesCount = 0; singlesCount < team.lineCountSingles; singlesCount++){
            lines.push({ type: "singles", timeOnly: lineTimes[lines.length], time: match.date, players: []});
          }
          for(let doublesCount = 0; doublesCount < team.lineCountDoubles; doublesCount++){
            lines.push({ type: "doubles", timeOnly: lineTimes[lines.length], time: match.date, players: []});
          }
  
        }
        match.lines = lines;
      }

      //Update calendar links...
      let matchICalLink = "https://us-central1-tennis-team-manager.cloudfunctions.net/createIcalForMatchV2?matchID=" + match.id + "&matchAddress=" + encodeURIComponent(match.homeTeam.address);
      let matchTitle = 'Tennis Match ' +  match.homeTeam.teamName + ' vs. ' + match.visitingTeam.teamName;
      let matchStartDateZulu = getZuluFormattedDate(match.date, 0);
      let matchEndDateZulu = getZuluFormattedDate(match.date, 3);
      let matchDetails = 'For more details, go to https://tennis.systems/match/' + match.id;
      let matchGoogleCalLink = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(matchTitle) + "&dates=" + matchStartDateZulu + "/" + matchEndDateZulu + "&details=" + matchDetails + "&location=" + encodeURIComponent(match.homeTeam.address);

      match.iCalLink = matchICalLink;
      match.googleCalLink = matchGoogleCalLink;
      console.log("Updated match: ", match);
     
      var thisTeamMembers = await getTeamMembers(userDoc.displayTeam);
      var membersById = {}
      thisTeamMembers.players.forEach(member => {
        membersById[member.uid] = member;
      });
      //console.log(membersById);
      //Now add them to the available and unavailable lists...
      var available = [];
      match.available.forEach(playerID => {
        available.push(membersById[playerID]);
      });
      var unavailable = [];
      match.unavailable.forEach(playerID => {
        unavailable.push(membersById[playerID]);
      });

      match.lines.forEach(line => {
        var playerObjects = [];
        line.players.forEach(playerID => {
          playerObjects.push(membersById[playerID])
        });
        line.playerObjects = playerObjects;
      });


      var noReplyPlayers = [];
      thisTeamMembers.players.forEach((member) =>{
        var noReply = true;
        if(match.available.includes(member.id)){
          noReply = false;
        }
        if(match.unavailable.includes(member.id)){
          noReply = false;
        }
        if(noReply){
          noReplyPlayers.push(member);
        }
      });
      match.noReplyPlayers = noReplyPlayers;  
    
      setTeam(team);
      setUserInfo(userDoc);
      setMatch(match);
      setTeamMembers(thisTeamMembers);
      setTeamMembersById(membersById)
      setAvailablePlayers(available);
      setUnavailablePlayers(unavailable);
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) {
      if(params.token){
        console.log("Logging User in with token...", params.token);

        signInWithCustomToken(auth, params.token)
          .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
            //TODO: Get Team for Match and then set user.displayTeam to team ID...
            window.open("/match/" + params.matchID, "_self");
            fetchData();
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("Error logging in with token: ", errorCode, errorMessage);
            //Redirect to login page...
            return navigate("/login");
        });

      } else {
        return navigate("/login");
      }
    } else {
      console.log('Already Logged In User UID: ', user.uid);
      fetchData();
    }
  }, [user, loading]);

  const getZuluFormattedDate = (fireBaseTimeStamp, hoursToAdd = 0) => {
    let jsDate = fireBaseTimeStamp.toDate();
    let updatedDate = addHours(jsDate, hoursToAdd);
    let formattedDate = formatInTimeZone(updatedDate, "Etc/UTC", "yyyyMMdd HHmmssXXX");
    formattedDate = formattedDate.replace(" ", "T");
    console.log("Formatted Date: ", formattedDate);
    return(formattedDate);
  }
  
  const getMatchTitleText = () => {
    if(!match){
      return (null);
    }

    let output = '';
    var lineupFinalDesc = 'Proposed';
    if(match.lineupStatus !== 'proposed'){
        lineupFinalDesc = 'Final';
    }
    output += lineupFinalDesc + " Lineup for ";

    output += dateStringForTimeZone(match.date.toDate(), 'America/Los_Angeles') + " Match - ";
    output += match.homeTeam.teamName + " (home) vs. " + match.visitingTeam.teamName + " (away)";
    return(output);
  }

  const getMatchLineupText = () => {
    
    let singlesCount = 0;
    let doublesCount = 0;
    let output = '';
    
    match.lines.map((line, index) => {
      if(line.players && line.players.length > 0){
        output += "\n";
        if(line.type === 'singles'){
          singlesCount = singlesCount + 1;
          output += titleCase(line.type) + " " + singlesCount + " (" + line.timeOnly + ")\n";
          output += teamMembersById[line.players[0]].firstName + ' ' + teamMembersById[line.players[0]].lastName + "\n";
        } else {
          doublesCount = doublesCount + 1;
          output += titleCase(line.type) + " " + doublesCount + " (" + line.timeOnly + ")\n"; 
          if(line.players.length === 2){
            output += teamMembersById[line.players[0]].firstName + ' ' + teamMembersById[line.players[0]].lastName + ' and ' + teamMembersById[line.players[1]].firstName + ' ' + teamMembersById[line.players[1]].lastName + "\n";
          }else{
            output += teamMembersById[line.players[0]].firstName + ' ' + teamMembersById[line.players[0]].lastName + "\n";
          }
        }
      }
    });

    return( output );
  }  
    
  const getMatchDetailText = () => {
    if(!match){
        return (null);
    }

      console.log(userInfo);
      console.log(availablePlayers);
      console.log(unavailablePlayers);

      let output = '';
      output += dateStringForTimeZone(match.date.toDate(), 'America/Los_Angeles');
      output += " " + match.homeTeam.teamName + " vs. " + match.visitingTeam.teamName + "\n";
      output += "Home Team: " +  match.homeTeam.teamName  + "\n";
      output += "Visiting Team: " +  match.visitingTeam.teamName + "\n";
      output += "Times: " +  match.times + "\n";
      output += "Match Address: " +  match.homeTeam.address + "\n\n";
      output += titleCase(match.lineupStatus) + " Lineup\n";
      output += getMatchLineupText();
      return(output);
  }

  const handleSendLineup = (event) => {
    console.log("Send lineup!", teamMembers.players);
    var emailList = []
    teamMembers.players.forEach((player) => {
      emailList.push(player.emailAddress);
    });

    var emailLink = "mailto:" + emailList.join(",") + "?subject=" + encodeURI(getMatchTitleText()) + "&body=" + encodeURI(getMatchDetailText())
    console.log(emailLink);
    window.open(emailLink);
  }

  const handleAvailableClick = (async(match, userInfo, event) => {
    console.log('Available Clicked...');
    console.log(match);
    console.log(userInfo);
    await setMatchAvailability(match, userInfo, true);
    fetchData();
  });

  const handleUnavailableClick = (async(match, userInfo, event) => {
    console.log('Available Clicked...');
    console.log(match);
    console.log(userInfo);
    await setMatchAvailability(match, userInfo, false);
    fetchData();
  });

  const handleEditLineup = ((event) => {
    console.log("Edit Lineup Clicked... ", "Singles Lines: ", team.lineCountSingles, " Doubles Lines: ", team.lineCountDoubles);

    if(match.lines && match.lines.length > 0){
      for(let i = 0; i < match.lines.length; i++){
        let currentLine = match.lines[i]
        if(currentLine.type === "singles"){
          if(match.lines[i].players && match.lines[i].players.length > 0){
            document.getElementById(i + ".0").value = match.lines[i].players[0];
          }
        } else {
          if(match.lines[i].players && match.lines[i].players.length > 1){
            document.getElementById(i + ".0").value = match.lines[i].players[0];
            document.getElementById(i + ".1").value = match.lines[i].players[1];
          }
        }
      }
    }

    const editLineupModal = editLineupModalRef.current;
    const bsModal = new window.bootstrap.Modal(editLineupModal);
    bsModal.show();    

  });

  const handleSaveLineup = ((event) =>{
    console.log('Save Lineup Clicked...');
    console.log("Before: ", match);

    let lineupSelects = document.getElementsByClassName("lineup-select");
    //console.log("Lineup Selects: ", lineupSelects);
    for(let i=0; i < lineupSelects.length; i++) {
      console.log("Selected Player: ", i, lineupSelects[i].id, lineupSelects[i].value); //, teamMembersById[lineupSelects[i].value].firstName, teamMembersById[lineupSelects[i].value].lastName);
      let lineIndex = lineupSelects[i].id.split(".");
      console.log(lineIndex);
      if(lineupSelects[i].value){
        match.lines[parseInt(lineIndex[0])].players[parseInt(lineIndex[1])] = lineupSelects[i].value;
        match.lines[parseInt(lineIndex[0])].playerObjects[parseInt(lineIndex[1])] = teamMembersById[lineupSelects[i].value];  
      } else {
        match.lines[parseInt(lineIndex[0])].players = []
        match.lines[parseInt(lineIndex[0])].playerObjects = []
      }
    }
    console.log("After: ", match);

    //const editLineupModal = editLineupModalRef.current;
    //const bsModal = window.bootstrap.Modal.GetInstance(editLineupModal);
    //bsModal.hide();    
    updateMatch(match);
    setMatch(match);
    fetchData();
  });

  const handleEditAvailability = ((event) => {
    console.log("Edit Availability Clicked... ");
    const editAvailabilityModal = editAvailableModalRef.current;
    const bsModal = new window.bootstrap.Modal(editAvailabilityModal);
    bsModal.show();    
  });


  const handleSaveAvailability = ((event) => {
    console.log("Save Availability Clicked")
    const editAvailabilityModal = editAvailableModalRef.current;
    //const bsModal = window.bootstrap.Modal.GetInstance(editAvailabilityModal);
    //bsModal.hide();    
  });

  const handleAvailabilityChange = (async(teamMember, isAvailable, event) =>{
    console.log("Save Availability Clicked. ", teamMember.firstName, teamMember.lastName, isAvailable);

    // Display update message...
    setShowAlert(true);

    await setMatchAvailability(match, teamMember, isAvailable);
    fetchData();

    let timeout = setTimeout(() => {
      setShowAlert(false);
    }, 1000);


  }); 

  const handleWinLoss = (async(won, event) => {
    console.log("Clicked WinLoss: ", won);

    // Display update message...
    setShowUpdateAlert(true);

    await setWonLoss(match, won);
    fetchData();

    let timeout = setTimeout(() => {
      setShowUpdateAlert(false);
    }, 5000);

  });

  //console.log('Rendering Matches...');
  let activeNav = "matches";
  let baseTitle = "Matches";
  let baselink = "/matches/";
  let link = "/match/" + params.matchID;
  let title = "Match Detail - Match ID " + params.matchID;
  let emptyMessage = 'No matches found...';

  let singlesCount = 0;
  let doublesCount = 0;

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        {match &&
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: baseTitle, link: baselink}, {title: title, link: link}]}/>
        }
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                    { !match && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    { team &&                     
                      <div>
                        <h3>{ team.teamTitle } - Match Detail</h3>
                        <h6 className="mb-2 text-muted border-top border-bottom py-2">
                          <strong>USTA Team Number</strong>: <a target="_blank" rel="noopener noreferrer" href={team.ustaLink}>{ team.id }</a> [click to open USTA team page]
                        </h6>
                        

                        <div className="my-5">
                        { match && 
                          <div>
                            <h5 className="mb-1">Match Detail for Match ID {match.id}</h5>
                            <div className="small mb-4">
                              <a className="link-secondary me-2" href={match.googleCalLink} target="_blank"><i className="bi bi-calendar-date-fill"></i> Add to Google Calendar</a>
                              <a className="link-secondary" href={match.iCalLink} target="_blank"><i className="bi bi-calendar-date-fill"></i> Add to Calendar (iCal)</a>
                            </div>

                            { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                              <div className="mb-4">
                                <h5 className="mb-1">Match Result</h5>
                                <div className="form-check-inline">
                                  <input className="form-check-input" type="radio" name="matchWon" id="matchWon"  checked={match.won !== undefined && match.won === true} onChange={handleWinLoss.bind(this, true)} />
                                  <label className="ms-2 form-check-label" htmlFor="matchWon">
                                    Won
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <input className="form-check-input" type="radio" name="matchWon" id="matchLost" checked={match.won !== undefined && match.won === false} onChange={handleWinLoss.bind(this, false)} />
                                  <label className="ms-2 form-check-label" htmlFor="matchLost">
                                    Lost
                                  </label>
                                </div>
                              </div>
                            }

                            <div>
                              <div>
                                <div className="text-black">
                                <strong>Match Date</strong>: { dateStringForTimeZone(match.date.toDate(), 'America/Los_Angeles') } <br />
                                <strong>Match Times</strong>: { match.times } <br />
                                <strong>Home Team</strong>: {match.homeTeam.teamName}<br />
                                <strong>Visiting Team</strong>: {match.visitingTeam.teamName}<br />
                                <strong>Match Location</strong>: {match.homeTeam.address}
                                </div>

                                <div>
                                  <div className="my-2">
                                  { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                    <div>
                                      <button className="btn btn-sm btn-outline-primary me-2" onClick={handleEditLineup}><i className="bi bi-pencil-fill me-2"></i>Edit Lineup</button>
                                      <button className="btn btn-sm btn-outline-primary me-2" onClick={handleSendLineup}><i className="bi bi-send-fill me-2"></i>Send Lineup</button>
                                    </div>
                                  }
                                  </div>
                                  {match.lines && match.lines.length > 0 && match.lines[0].playerObjects && match.lines[0].playerObjects.length > 0 &&
                                  <div className="my-5">
                                    <h4>{titleCase(match.lineupStatus)} Lineup:</h4>
                                    {match.lines.map((line, index) =>(
                                      <div className="row text-black" key={index}>
                                        <div className="col">
                                          <div className="mb-1">
                                          { line.type === 'singles'
                                          ? (singlesCount = singlesCount + 1) && <> <strong>Singles {singlesCount}</strong>. {line.playerObjects.length === 1 ? line.playerObjects[0].firstName + " " + line.playerObjects[0].lastName + " (" + line.timeOnly + ")" : ''}</>
                                          : (doublesCount = doublesCount + 1) &&<> <strong>Doubles {doublesCount}</strong>. {line.playerObjects.length === 2 ? line.playerObjects[0].firstName + " " + line.playerObjects[0].lastName + " & " + line.playerObjects[1].firstName + " " + line.playerObjects[1].lastName + " (" + line.timeOnly + ")" : ''}</>
                                          }
                                          </div>
                                        </div>
                                      </div>
                                    ))}  
                                  </div>
                                  }
                                  <div className="row mt-2">
                                    <div className="col-12 col-xl-6">
                                      <h4>
                                      Available Players ({availablePlayers.length})
                                      { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                      <a className="ms-2 link-primary" name="editAvailablePlayers" id="editAvailablePlayers" onClick={handleEditAvailability} title="Edit Availability" alt="Edit Availability"><i className="bi bi-plus-circle-fill"></i></a>
                                      }
                                      </h4>

                                      <button type="button" className="btn btn-outline-success me-2" onClick={handleAvailableClick.bind(this, match, userInfo)}>
                                      { match.available.includes(userInfo.uid)
                                      ? <span><i className='bi bi-hand-thumbs-up-fill text-success'></i> I'm Available</span>
                                      : <span><i className='bi bi-hand-thumbs-up'></i> I'm Available</span>
                                      }
                                      </button>
                                      <ol className="my-4">
                                        {Object.values(availablePlayers).map((player) => (
                                          <li key={player.uid}>{player.firstName} {player.lastName} - {player.ntrp} (<a href={"tel:" + player.mobilePhone}>{player.mobilePhone}</a>)</li>
                                        ))}
                                      </ol>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                      <h4>Unavailable Players ({unavailablePlayers.length})</h4>
                                      <button type="button" className="btn btn-outline-danger me-2" onClick={handleUnavailableClick.bind(this, match, userInfo)}>
                                      { match.unavailable.includes(userInfo.uid)
                                      ? <span><i className="bi bi-hand-thumbs-down-fill text-danger"></i> I'm Unavailable</span>
                                      : <span><i className="bi bi-hand-thumbs-down"></i> I'm Unavailable</span>
                                      }
                                      </button>

                                      <ol className="my-4">
                                        {Object.values(unavailablePlayers).map((player) => (
                                          <li key={player.uid}>{player.firstName} {player.lastName} - {player.ntrp} (<a href={"tel:" + player.mobilePhone}>{player.mobilePhone}</a>)</li>
                                        ))}
                                      </ol>
                                    </div>
                                  </div>

                                  <div className="row mt-2">
                                    <div className="col-12 col-xl-6">
                                      <h4>No-Reply ({match.noReplyPlayers.length})</h4>

                                      <ol className="my-4">
                                        {Object.values(match.noReplyPlayers).map((player) => (
                                          <li key={player.uid}>{player.firstName} {player.lastName} - {player.ntrp} (<a href={"tel:" + player.mobilePhone}>{player.mobilePhone}</a>)</li>
                                        ))}
                                      </ol>
                                    </div>
                                  </div>


                                  <h4>Map to Match Location</h4>
                                  <iframe src={match.homeTeam.map} width="100%" height="450" allowFullScreen="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>

      <div className="modal fade" id="editLineup" tabIndex="-1" aria-labelledby="editLineupLabel" aria-hidden="true" ref={editLineupModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="editLineupLabel">Edit Lineup</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            {availablePlayers && availablePlayers.length > 0 && match.lines.map((line, index) =>(
                <div className="row" key={index}>
                  <div className="col">
                    <div className="mb-1">
                    {line.type === 'singles'
                    ? <div className="text-black mb-5">Singles @ {line.timeOnly}
                        <select id={index + ".0"} className="form-select lineup-select">
                          <option value="">Choose a player...</option>
                          { availablePlayers.map((player) => (
                            <option key={player.uid} value={player.uid}>{player.firstName} {player.lastName}</option>
                          ))}
                        </select>
                      </div>
                    : <div className="text-black mb-5">Doubles @ {line.timeOnly}

                        <select id={index + ".0"} className="form-select lineup-select">
                          <option value="">Choose a player...</option>
                          { availablePlayers.map((player) => (
                            <option key={player.uid} value={player.uid}>{player.firstName} {player.lastName}</option>
                          ))}
                        </select>
                        <select id={index + ".1"} className="form-select lineup-select">
                          <option value="">Choose a player...</option>
                          { availablePlayers.map((player) => (
                            <option key={player.uid} value={player.uid}>{player.firstName} {player.lastName}</option>
                          ))}
                        </select>

                      </div>
                    }
                    </div>
                  </div>
                </div>
              ))}                
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveLineup}>Save Lineup</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="editAvailable" tabIndex="-1" aria-labelledby="editAvailableLabel" aria-hidden="true" ref={editAvailableModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="editAvailableLabel">Edit Available Players</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black">
            { teamMembers && teamMembers.players &&teamMembers.players.length > 0 && teamMembers.players.map((teamMember) =>(
                <div className="row border-bottom mb-2" key={teamMember.id}>
                  <div className="col">
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name={"Available" + teamMember.id} id={"isAvailable-" + teamMember.id}  checked={match.available.includes(teamMember.id)} onChange={handleAvailabilityChange.bind(this, teamMember, true)} />
                      <label className="ms-2 form-check-label" htmlFor={"isAvailable-" + teamMember.id}>
                        Available
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name={"Available" + teamMember.id} id={"isNotAvailable-" + teamMember.id} checked={match.unavailable.includes(teamMember.id)} onChange={handleAvailabilityChange.bind(this, teamMember, false)} />
                      <label className="ms-2 form-check-label" htmlFor={"isNotAvailable-" + teamMember.id}>
                        Unavailable
                      </label>
                    </div>
                    <div className="d-inline">
                      {teamMember.firstName} {teamMember.lastName}
                    </div>

                  </div>
                </div>
              ))}                
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary d-none" onClick={handleSaveAvailability}>Save Availability</button>
            </div>
          </div>
        </div>
        { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>

      { showUpdateAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
          <div id="showUpdateAlert" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-body">
                  <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
              </div>
          </div>
        </div>
      )}


      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default MatchDetail;