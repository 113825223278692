import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
//import { isBefore } from "date-fns";
import { getTeam, getFacilities } from "./firebaseData";
//import { Timestamp } from "firebase/firestore";
import "./Facilities.css";

function Facilities(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if(!user.uid === 'W3dBoKNktkR8yp2ByjoRR7okfVn2') return navigate("/");

    console.log('User UID: ', user.uid);

    async function fetchData(){
      const userDoc = await getUser(user);
      setUserInfo(userDoc);
      setVersionID(await fetchVersionInfo());
      console.log("Props: ", props);

      setTeam(await getTeam(userDoc.displayTeam));
      setFacilities(await getFacilities());
    }
    fetchData();

  }, [user, loading]);

  //console.log('Rendering Matches...');
  let title = "Facilities";
  let activeNav = "facilities";
  let link = "/failities";
  let emptyMessage = 'No facilities found...';

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                      <div>
                        <h3>Facilities</h3>
                      
                        <div className="my-5">
                        { facilities && 
                          <h5 className="clearfix">Facilities ({facilities.length})</h5>
                        }
                        <div className="row row-cols-md-1">
                        { facilities.length === 0 && 
                          <div className="text-center">
                              <h2>Loading...</h2>
                                <Rings
                                height="180"
                                width="180"
                                color="#0000ff"
                                radius="6"
                                wrapperStyle={{display:"block"}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="rings-loading"
                              />
                          </div>
                        }

                        {facilities && facilities.map(facility => (
                          <div className="col" key={facility.id}>
                          <div className="card my-2">
                            <div className="card-body">
                              <div className="card-title text-black">
                                <strong>Name</strong>: { facility.name } <br />
                                <strong>Address</strong>: { facility.address }
                              </div>
                              <div className="card-text">
                                <div className="accordion accordion-flush" id={ facility.name.replaceAll(' ', '-') }>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={ "#collapse" + facility.name.replaceAll(' ', '-') } aria-expanded="false" aria-controls={ "collapse" + facility.name.replaceAll(' ', '-') }>Map</button>
                                    </h2>
                                    <div id={ "collapse" + facility.name.replaceAll(' ', '-') } className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent={ "#" + facility.name.replaceAll(' ', '-') }>
                                      <div className="accordion-body">
                                        <iframe src={facility.mapEmbed} width="100%" height="450" allowFullScreen="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        ))}
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Match Availability Emails Enqueued!</p>                    
                </div>
            </div>
          </div>
      )}


      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Facilities;